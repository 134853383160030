<template>
  <app-bar
    title="Kata Sandi"
    :previousLink="{
      name: 'Profil'
    }"
    :noBackButtonOnDesktop="true"
    class="sub"
  />
  <Form
    @submit="update"
    v-slot="{ errors }"
    class="mt-3 mt-md-2">

    <section class="mb-3">
      <label>Kata Sandi Lama</label>
      <div class="input-group">
        <Field
          :type="typePasswordLama"
          v-model="form.password_lama"
          name="password_lama"
          rules="required"
          class="form-control"
          :class="{ 'is-invalid': errors.password_lama }"
          placeholder="Kata Sandi Lama" />
        <span class="input-group-text bg-grayscale border-0">
          <div
            class="show-password"
            type="button"
            @click="typePasswordLama = showPassword(typePasswordLama)">
            <i class="ri-eye-fill fs-24" v-if="typePasswordLama === 'password'"></i>
            <i class="ri-eye-off-fill fs-24" v-if="typePasswordLama === 'text'"></i>
          </div>
        </span>
        <span v-if="!errors.password_lama" class="invalid-feedback">{{ errorMessage }}</span>
        <ErrorMessage name="password_lama" class="invalid-feedback" />
      </div>
    </section>

    <section class="mb-3">
      <label>Kata Sandi Baru</label>
      <div class="input-group">
        <Field
          :type="typePasswordBaru"
          v-model="form.password_baru"
          name="password_baru"
          rules="required"
          class="form-control"
          :class="{ 'is-invalid': errors.password_baru }"
          placeholder="Kata Sandi" />
        <span class="input-group-text bg-grayscale border-0">
          <div class="show-password" type="button" @click="typePasswordBaru = showPassword(typePasswordBaru)">
            <i class="ri-eye-fill fs-24" v-if="typePasswordBaru === 'password'"></i>
            <i class="ri-eye-off-fill fs-24" v-if="typePasswordBaru === 'text'"></i>
          </div>
        </span>
        <span v-if="!errors.password_baru" class="invalid-feedback">{{ errorMessage }}</span>
        <ErrorMessage name="password_baru" class="invalid-feedback" />
      </div>
    </section>

    <section class="mb-3">
      <label>Konfirmasi Kata Sandi Baru</label>
      <div class="input-group">
        <Field
          :type="typePasswordKonfirmasi"
          name="password_konfirmasi"
          rules="required|confirmed:password_baru"
          class="form-control"
          :class="{ 'is-invalid': errors.password_konfirmasi }"
          placeholder="Kata Sandi Baru" />
        <span class="input-group-text bg-grayscale border-0">
          <div class="show-password" type="button" @click="typePasswordKonfirmasi = showPassword(typePasswordKonfirmasi)">
            <i class="ri-eye-fill fs-24" v-if="typePasswordKonfirmasi === 'password'"></i>
            <i class="ri-eye-off-fill fs-24" v-if="typePasswordKonfirmasi === 'text'"></i>
          </div>
        </span>
        <span v-if="!errors.password_konfirmasi" class="invalid-feedback">{{ errorMessage }}</span>
        <ErrorMessage name="password_konfirmasi" class="invalid-feedback" />
      </div>
    </section>

    <div class="row">
      <div class="col-md-4">
        <span class="d-block text-center text-error fm-nunito fs-16 fw-600">{{ errorMessage }}</span>
        <button
          class="btn btn-primary w-100 pt-2 pb-2"
          type="submit"
          :disabled="loading.changePassword">
          {{ loading.changePassword ? 'Mengganti' : 'Simpan' }}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { nextTick, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { title } from '@/core/page'
import { user, loading, changePassword, showPassword } from '@/core/auth'
import { showNotif } from '@/core/utility'

export default {
  components: { AppBar, Field, Form, ErrorMessage },
  setup () {
    nextTick(() => {
      title.value = 'Update Data Diri'
    })

    const typePasswordLama = ref('password')
    const typePasswordBaru = ref('password')
    const typePasswordKonfirmasi = ref('password')
    const router = useRouter()
    const errorMessage = ref('')
    const form = reactive({
      email: user.value.email_user,
      password_lama: '',
      password_baru: ''
    })

    const update = async () => {
      try {
        errorMessage.value = ''
        const result = await changePassword(form)
        // Trigger toast
        showNotif({
          status: (result.status) ? 'success' : 'error',
          message: result.message
        })

        if (result.status) {
          router.push({ name: 'Profil' })
        }
      } catch (error) {
        errorMessage.value = error.response.data.message
        showNotif({
          status: 'error',
          message: error.response.data.message
        })
      }
    }

    return {
      form,
      errors: {},
      loading,
      update,
      errorMessage,
      showPassword,
      typePasswordLama,
      typePasswordBaru,
      typePasswordKonfirmasi
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  aspect-ratio: 1 / 1;
  border-radius: .4rem;
  width: 5.75rem;
  object-fit: cover;

  @media (min-width: 520px) {
    width: 5rem;
  }
}

.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.input-group>.form-control.form-error {
  background: #FFDDEB;
  color: #14142B;
}
</style>
